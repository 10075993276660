import React from 'react'
import './hablemos.css'
// import { Link } from "react-router-dom"

export default function Hablemos() {
  return (
    <div className='containerHablemos' id="SectionHablemos">
      {/* <a></a> */}
      <div className="container">
        <div className='leftHablemos'>
          <h1 className='mainHeaderHablemosR'>Ahorremos</h1>
          <a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/">
            <img className="imgSagyTalk" src={process.env.PUBLIC_URL + 'SagyHablemos.png'} alt="SagyTalk.png" />
          </a>
        </div>
        <div className='rigthHablemos'>
          <h1 className='mainHeaderHablemos'>¡Ahorremos!</h1>
          <p className='bodyHablemos'>Cada mes que pasas sin revisar tu factura de servicios públicos, es una <span className='SagyTitleHablemos'>oportunidad de ahorro</span> que se pierde. No lo pienses más y empieza a ahorrar con <span className='SagyTitleHablemos'>Sagy</span>.</p>
          <button className="buttonSagyHablemos"><a className="link" href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Quisiera%20ahorrar%20en%20mi%20factura%20de%20servicios%20p%C3%BAblicos." target="_blank" rel="noopener noreferrer"><img className="wpp" src={process.env.PUBLIC_URL + 'Wpp.png'} alt="" /></a><a className="linkAx" href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Quisiera%20ahorrar%20en%20mi%20factura%20de%20servicios%20p%C3%BAblicos." target="_blank" rel="noopener noreferrer">Haz clic aquí</a></button>
        </div>
      </div>
    </div>
  )
}
