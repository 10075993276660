import React from 'react' 
import './reporting.css'

// componentes
import LoginComponent from '../components/login/Login'

export default function Reporting_embed() {
  return (
    <LoginComponent />
  )
}