import SoySagy from '../components/soysagy/SoySagy'
import ComoTeAyudo from '../components/comoteayudo/ComoTeAyudo'
import PorqueElegirme from '../components/porqueelegirme/PorqueElegirme'
import Dudas from '../components/dudas/Dudas'
import Hablemos from '../components/hablemos/Hablemos'
import Footer from '../components/footer/Footer'
import Apoyan from '../components/apoyan/Apoyan'
import Pertenecen from '../components/pertenecen/Pertenecen'
import FloatingButton from "../components/whatsapp/FloatingButton"
import Medios from '../components/losmedios/medios';
import Plataforma from '../components/plataforma/plataforma';
import './home.css' 

export default function Home() {
  return (
    <>
        <div className="home">
          <FloatingButton/>
          <SoySagy/>
          <ComoTeAyudo/>
          <Plataforma/>
          <PorqueElegirme/>
          <Medios />
          <Dudas/>
          <Pertenecen/>
          <Hablemos/>
          <Apoyan/>
          <Footer/>
        </div>
    </>
  )
}
