import React from 'react'
import './menures.css'
// import { Link } from "react-router-dom"

export default function Menures() {
    return (
        <div className='MenuRes'>
            <div className='topResponsive'>
                <label for="checkR" className="checkbtn2" id="IdCheck2">
                    <span className="simpleX">X</span>
                </label>
            </div>
            <div className='midleResponsive'>
                <ul className='listRes'>
                    <li className='itemRespoM'><label for="checkR" className="checkbtn2" id='IdCheck3'><a className="itemRespoM" href="/#SectionSoySagy" onClick={() => document.getElementById('checkR').checked = false}>Soy Sagy</a></label></li>
                    <li className='itemRespo'><label for="checkR" className="checkbtn2" id='IdCheck4'><a className="itemRespo" href="/#SectionComoTeAyudo" onClick={() => document.getElementById('checkR').checked = false}>¿Cómo te ayudamos?</a></label></li>
                    <li className='itemRespoM'><label for="checkR" className="checkbtn2" id='IdCheck5'><a className="itemRespoM" href="/reportes" onClick={() => document.getElementById('checkR').checked = false}>Plataforma</a></label></li>
                    <li className='itemRespo'><label for="checkR" className="checkbtn2" id='IdCheck6'><a className="itemRespo" href="/#SectionPorqueElegirme" onClick={() => document.getElementById('checkR').checked = false}>¿Por qué elegirnos?</a></label></li>
                    <li className='itemRespo'><label for="checkR" className="checkbtn2" id='IdCheck7'><a className="itemRespo" href="/#SectionDudas" onClick={() => document.getElementById('checkR').checked = false}>¿Dudas?</a></label></li>
                    <li className='itemRespo'><label for="checkR" className="checkbtn2" id='IdCheck8'><a className="itemRespo" href="/#SectionHablemos" onClick={() => document.getElementById('checkR').checked = false}>Ahorremos</a></label></li>
                </ul>
            </div>
            <div className='bottomResponsive'>
                <a href="https://www.instagram.com/sagy.co/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer"><img className="imgInstagramR" src={process.env.PUBLIC_URL + 'IgIcon.png'} alt="IgLogo.png" /></a>
                <a href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Quisiera%20ahorrar%20en%20mi%20factura%20de%20servicios%20p%C3%BAblicos." target="_blank" rel="noopener noreferrer"><img className="imgWppR" src={process.env.PUBLIC_URL + 'WsIcon.png'} alt="WppLogo.png" /></a>
            </div>
        </div>
    )
}
