import React from 'react';
import './apoyan.css';

export default function Apoyan() {
  return (
    <div className='containerAyudan'>
      <div className='container'>
        <div className='leftAyudan'>
          <h1 className='headerAyudan'>Nos apoyan:</h1>
        </div>
        <div className='rightAyudan'>
          <a href="https://startups.microsoft.com" target="_blank" rel="noopener noreferrer">
            <img className="imgAyudan" src={process.env.PUBLIC_URL + 'microsoft-for-startups.png'} alt="Microsoft for Startups" />
          </a>
          <a href="https://www.fondoemprender.com" target="_blank" rel="noopener noreferrer">
            <img className="imgAyudan" src={process.env.PUBLIC_URL + 'sena-fondo-emprender.png'} alt="SENA Fondo Emprender" />
          </a>
          <a href="https://ylai.state.gov" target="_blank" rel="noopener noreferrer">
            <img className="imgAyudan" src={process.env.PUBLIC_URL + 'young-leaders-of-the-americas-initiative.png'} alt="Young Leaders of the Americas Initiative" />
          </a>
          <a href="https://www.raeng.org.uk" target="_blank" rel="noopener noreferrer">
            <img className="imgAyudan" src={process.env.PUBLIC_URL + 'royal-academy-of-engineering.png'} alt="Royal Academy of Engineering" />
          </a>
        </div>
      </div>
    </div>
  );
}
