import React from 'react';
import Marquee from 'react-fast-marquee';
import './pertenecen.css';

export default function Pertenecen() {
  const logos = [
    {src: process.env.PUBLIC_URL + 'grupo-jaibu.png', alt: 'grupo-jaibu.png', url: 'https://www.inversionesjaibu.com/'},
    {src: process.env.PUBLIC_URL + 'grupo-reditos-gana.png', alt: 'grupo-reditos-gana.png', url: 'https://www.gruporeditos.com/'},
    {src: process.env.PUBLIC_URL + 'invesa.png', alt: 'invesa.png', url: 'https://www.invesa.com/'},
    {src: process.env.PUBLIC_URL + 'sulfoquimica.png', alt: 'sulfoquimica.png', url: 'https://www.sulfoquimica.com/'},
    {src: process.env.PUBLIC_URL + 'tintatex.png', alt: 'tintatex.png', url: 'https://www.tintatex.co/' },
    {src: process.env.PUBLIC_URL + 'brahma.png', alt: 'brahma.png', url: 'https://www.brahma.co/'},
    {src: process.env.PUBLIC_URL + 'color-plastic.png', alt: 'color-plastic.png', url: 'https://colorplasticcolombia.com/'},
    {src: process.env.PUBLIC_URL + 'solvo.png', alt: 'solvo.png', url: 'https://solvoglobal.com/'},
    {src: process.env.PUBLIC_URL + 'cauchos-echeverri.png', alt: 'cauchos-echeverri.png', url: 'https://cauchosecheverri.com/'},
    {src: process.env.PUBLIC_URL + 'keralty.png', alt: 'keralty.png', url: 'https://www.keralty.com/'},
    {src: process.env.PUBLIC_URL + 'alsec.png', alt: 'alsec.png', url: 'https://alsec.com.co/'},
    {src: process.env.PUBLIC_URL + 'auteco-mobility.png', alt: 'auteco-mobility.png', url: 'https://www.auteco.com'},
    {src: process.env.PUBLIC_URL + 'agroban.png', alt: 'agroban.png', url: 'https://www.facebook.com/p/Agroban-SAS-100064327620092/'},
    {src: process.env.PUBLIC_URL + 'plasticos-truher.png', alt: 'plasticos-truher.png', url: 'https://www.plasticostruher.com/'},   
    {src: process.env.PUBLIC_URL + 'incolmotos-yamaha.png', alt: 'incolmotos-yamaha.png', url: 'https://www.incolmotos-yamaha.com.co/'},
    {src: process.env.PUBLIC_URL + 'viappiani.png', alt: 'viappiani.png', url: 'https://www.viappiani.com.co/'},
  ];

  return (
    <>
      <section className="containerPertenecen">
        <div className="carouselContainer">
          <div className="headEmpresas">
            <h1>Confían en nosotros</h1>
          </div>
          <Marquee
            pauseOnHover={true}
            className="marquee"
          >
            {logos.map((logo, index) => (
              <a
                key={index}
                href={logo.url}
                target="_blank"
                rel="noopener noreferrer"
                className="logoItem"
              >
                <img src={logo.src} alt={logo.alt} />
              </a>
            ))}
          </Marquee>
        </div>
      </section>
    </>
  );
}
