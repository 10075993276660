import React, { useState } from 'react';
import './dudas.css';

export default function Dudas() {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='dudas' id='SectionDudas'>
            <div className='container'>
                <div className='leftDudas'>
                    <div className='headerDudas'>
                        <h1 className='mainHeaderDudas'>¿Dudas?</h1>
                        <h2 className='secondHeaderDudas'>Aquí te ayudamos a resolverlas</h2>
                    </div>
                    <input 
                        type='checkbox' 
                        id='btnView' 
                        className='btnMostrar' 
                        checked={isExpanded} 
                        onChange={handleToggle} 
                    />
                    <ul className='mainListDudas'>
                        <ul className='firstListDudas'>
                            {/* Primer grupo de preguntas */}
                            <li className='firstListitem1'>
                                <div className='headDudas'>
                                    <p>¿Puedo inscribir cualquier tipo de establecimiento?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListFirstItem1'>
                                    <li>Sí. Puedes registrar tu empresa o la empresa donde trabajas a nuestro plan free si la factura de servicios públicos es de al menos <strong className='dudasSagyTitle'>$2.000.000</strong> mensual.</li>
                                </ul>
                            </li>
                            <li className='firstListitem3'>
                                <div className='headDudas'>
                                    <p>¿Cuánto vale el servicio de <span className='dudasSagyTitle'>Sagy</span>?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListFirstItem3'>
                                    <li>Trabajamos por resultados. Nuestra remuneración corresponde a un porcentaje del ahorro que te generemos. <span className='dudasSagyTitle'>¡Si NO ahorras, NO pagas!</span></li>
                                </ul>
                            </li>
                            <li className='firstListitem5'>
                                <div className='headDudas'>
                                    <p>¿Cuántas facturas puedo inscribir?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListFirsttem5'>
                                    <li>Puedes inscribir tantas facturas como desees.</li>
                                </ul>
                            </li>
                            <li className='secondListitem7'>
                                <div className='headDudas'>
                                    <p>¿Cuándo debo pagar a <span className='dudasSagyTitle'>Sagy</span> por su servicio?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListFirstItem7'>
                                    <li>Cuando veas reflejado el ahorro directamente en tu factura de servicios públicos. Te mostraremos todas las evidencias de que tu ahorro se debe a nuestra labor.</li>
                                </ul>
                            </li>
                        </ul>
                        {/* Botón para mostrar/ocultar más preguntas */}
                        <label htmlFor="btnView" id="labelView">
                            {isExpanded ? 'Mostrar menos' : 'Mostrar más'}
                        </label>
                        {/* Segundo grupo de preguntas */}
                        <ul className={`secondListDudas ${isExpanded ? '' : 'hidden'}`} id='secondListDuda'>
                            <li className='secondListitem1'>
                                <div className='headDudas'>
                                    <p>¿Cómo puedo hacer la inscripción de mi factura?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListSecondItem1'>
                                    <li>Reserva un espacio de reunión con uno de nuestros agentes: <a href='https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/' target='_blank_'>Clic aquí</a></li>
                                </ul>
                            </li>
                            <li className='secondListitem2'>
                                <div className='headDudas'>
                                    <p>¿Tengo que compartir mi factura cada mes en <span className='dudasSagyTitle'>Sagy</span>?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListSecondItem2'>
                                    <li>No. La inscripción se hace una única vez.</li>
                                </ul>
                            </li>
                            <li className='secondListitem3'>
                                <div className='headDudas'>
                                    <p>¿Tengo que pagar para inscribir mi factura?</p>
                                    <div>
                                        <img className="imgSignalDudas" src={process.env.PUBLIC_URL + 'SignalDudas.png'} alt="SignalDudas.png" />
                                    </div>
                                </div>
                                <ul className='subListSecondItem3'>
                                    <li>No. El proceso de inscripción es totalmente <span className='dudasSagyTitle'>gratuito</span>.</li>
                                </ul>
                            </li>
                        </ul>
                    </ul>
                </div>
                <div className='rigthDudas'>
                    <a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/">
                        <img className="imgSagyDudas" src={process.env.PUBLIC_URL + 'SagyDudas.png'} alt="SagyDudas.png" />
                    </a>
                </div>
            </div>
        </div>
    );
}
