import Menures from "../menures/Menures"
import "./topbar.css"
import { Link } from "react-scroll";

// import { Link } from "react-router-dom"


export default function TopBar() {
    return (
        <div className="top">
            <div class="topContainer">
                <input type='checkbox' id='checkR' className='btnRTop' />
                <div className="auxMenu"><Menures /></div>
                <div className="topLeft">
                    <a href="/" target=""><img className="topIconSagy" src={process.env.PUBLIC_URL + 'Sagy Logo.png'} alt="logo" /></a>
                </div>
                <div className="buttonResponsive">
                    {/* <button className="topListAhorremosButton"><a className="link"  href="https://wa.me/573116756456" target="_blank">Ahorremos</a></button> */}
                    <button className="topListAhorremosButton"><a className="link" href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/" target="_blank" rel="noopener noreferrer">Ahorremos</a></button>
                    <label for="checkR" className="checkbtn" id="IdCheck">
                        <img className="imgBar" src={process.env.PUBLIC_URL + 'menubar.png'} alt="BarMenu.png" />
                    </label>
                </div>
                <div className="secondSectionTop">
                    <div className="topCenter">
                        <ul className="topList">
                            <li className="topListItem">
                                <Link
                                    activeClass="active"
                                    to="SectionSoySagy"
                                    spy={true}
                                    smooth={true}
                                    offset={-89}
                                    duration={500}
                                    className="linkCenter">
                                    {/**<Link className="linkCenter" to="/SoySagySect">Soy Sagy</Link>**/}
                                    Soy <span className="topBarSagyTitle">Sagy</span>
                                </Link>
                            </li>
                            <li className="topListItem">
                                <Link
                                    activeClass="active"
                                    to="SectionComoTeAyudo"
                                    spy={true}
                                    smooth={true}
                                    offset={-89}
                                    duration={500}
                                    className="linkCenter">
                                    {/** <Link className="linkCenter" to="/">¿Cómo te ayudamos?</Link>**/}
                                    ¿Cómo te ayudamos?
                                </Link>
                            </li>
                            <li className="topListItem">
                                <Link
                                    activeClass="active"
                                    to="SectionPlataforma"
                                    spy={true}
                                    smooth={true}
                                    offset={-89}
                                    duration={500}
                                    className="linkCenter">
                                    {/**<Link className="linkCenter" to="/">Opiniones</Link>**/}
                                    <span className="topBarSagyTitle">Plataforma</span>
                                </Link>
                            </li>
                            <li className="topListItem">
                                <Link
                                    activeClass="active"
                                    to="SectionPorqueElegirme"
                                    spy={true}
                                    smooth={true}
                                    offset={-89}
                                    duration={500}
                                    className="linkCenter">
                                    {/**<Link className="linkCenter" to="/">¿Por qué elegirnos?</Link>**/}
                                    ¿Por qué elegirnos?
                                </Link>
                            </li>
                            <li className="topListItem">
                                <Link
                                    activeClass="active"
                                    to="SectionDudas"
                                    spy={true}
                                    smooth={true}
                                    offset={-89}
                                    duration={500}
                                    className="linkCenter">
                                    {/**<Link className="linkCenter" to="/">¿Dudas?</Link>**/}
                                    ¿Dudas?
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="topRight">{
                        <ul className="topListR">
                            <li className="topListItemRButton">
                                <button className="topListAhorremosButton"><a className="link" href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/" target="_blank" rel="noopener noreferrer">Ahorremos</a></button>
                            </li>
                            <li className="topListItemRButton">
                                <button className="topListPlataformaButton"><a className="link" href="/reportes" target="_blank">Plataforma</a></button>
                            </li>
                            {/* <li className="topListItemR">
                                <a href="https://www.instagram.com/sagy.co/?igshid=YmMyMTA2M2Y%3D" target="_blank"><img className="topIcon" src={process.env.PUBLIC_URL + 'InsFooter.png'} alt="IgLogoFooter.png"/></a>
                                <a href="https://wa.me/573116756456" target="_blank"><img className="topIcon" src={process.env.PUBLIC_URL + 'FooterWpp.png'} alt="WppLogo.png"/></a>
                            </li> */}
                        </ul>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}
