// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import './fonts/futur.ttf';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


import React from 'react';
import ReactDOM from 'react-dom/client'; // Cambiado de 'react-dom' a 'react-dom/client'
import App from './App';
import './fonts/futur.ttf';

// Crear la raíz con createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderizar el componente App
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
