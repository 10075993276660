// import React, { useState } from 'react'
// import axios from 'axios'


// // Import your loading GIF here
// import loadingGif from "../../assets/loading.gif"; 

// import './login.css'

// // componentes
// import ReportComponent from '../report/Report.jsx'

// export default function Login() {
//     const [id, setId] = useState('');
//     const [password, setPassword] = useState('');
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [token, setToken] = useState(null);
//     const [rol, setRol] = useState(0);
//     const [errorMessage, setErrorMessage] = useState('');
//     const [isLoading, setIsLoading] = useState(false); // State for loading status

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setErrorMessage('');
//         setIsLoading(true); // Start loading

//         try {
//             const response = await axios.post('https://rjkm62ow34.execute-api.us-east-1.amazonaws.com/staging/getToken/getToken', {
//                 "usuario" : id,
//                 "contrasena" : password
//             });

//             if (response.data && response.data.embedToken) {
//                 setIsLoading(false); // Stop loading after the API call is done

//                 setToken(response.data.embedToken);
//                 setRol(response.data.rol);

//                 setIsLoggedIn(true);
//             } else {
//                 setIsLoading(false); // Stop loading after the API call is done
//                 setErrorMessage('Error al intentar ingresar. Por favor verifique sus credenciales.');
//                 console.error('Login failed:', response.data);
//             }
//         } catch (error) {
//             setIsLoading(false); // Stop loading after the API call is done
//             if (error.code === "ERR_BAD_REQUEST") {
//                 setErrorMessage('Error al intentar ingresar. Por favor verifique sus credenciales.');
//                 console.error('Login failed:', error.response.data);
//             }else{
//                 setErrorMessage('Error al intentar ingresar. Por favor intente más tarde. código de error:' + error.code);
//                 console.error('Login failed:', error);
//             }
//         }
//     };

//     if (isLoggedIn) {
//         return <ReportComponent token={token} id={id} rol={rol}/>;
//     }

//     return (
//         <div className="login-page">
//             {isLoading && (
//                 <div className="loading-container">
//                     <img src={loadingGif} alt="Loading..." />
//                 </div>
//             )}
//             <div className="login-container" style={{ opacity: isLoading ? 0.5 : 1 }}>
//                 {errorMessage && <div className="error-message">{errorMessage}</div>}
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label htmlFor="id">Identificador</label>
//                         <input
//                             type="text"
//                             pattern="admin|demo|\d+"
//                             title="Por favor ingrese un usuario válido."
//                             id="id"
//                             value={id}
//                             onChange={(e) => setId(e.target.value)}
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="password">Contraseña</label>
//                         <input
//                             type="password"
//                             title="Por favor ingrese una contraseña válido."
//                             id="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                         />
//                     </div>
//                     <button type="submit" disabled={isLoading}>Ingresar</button>
//                 </form>
//             </div>
//         </div>
//     )
// }


import React, { useState } from 'react';
import axios from 'axios';
import loadingGif from "../../assets/loading.gif";
import './login.css';
import CryptoJS from "crypto-js";
import ReportComponent from '../report/Report.jsx'

export default function Login() {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [embedToken, setEmbedToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rol, setRol] = useState(0);
    const secretKeyBase64 = process.env.REACT_APP_SECRET_KEY;

    const secretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);

    const cifrarTexto = (texto) => {
        try {
            const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(texto), secretKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
        } catch (error) {
            console.error("Error al cifrar los datos:", error);
            throw error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setIsLoading(true);

        try {
            const usuarioCifrado = cifrarTexto(id);
            const contraseñaCifrada = cifrarTexto(password);

            const authResponse = await axios.post('https://sagyplataforma.azurewebsites.net/api/login?code=WXaKakX8BuSFG9Jkvfk6zAzeFXVJ_bknfPim6N40YbAUAzFum-Wfcg%3D%3D', {
                id: usuarioCifrado,
                password: contraseñaCifrada
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (authResponse.status === 200 && authResponse.data) {
                const { embedToken, rolCliente } = authResponse.data;
                setEmbedToken(embedToken);
                setRol(rolCliente ? 1 : 0);
                setIsLoggedIn(true);
            } else if (authResponse.status === 401) {
                setErrorMessage('Usuario o contraseña incorrectos.');
            } else {
                setErrorMessage('Error inesperado. Por favor intente más tarde.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setErrorMessage('Usuario o contraseña incorrectos.');
                } else if (error.response.status === 500) {
                    setErrorMessage('Problema con el servidor. Por favor intente más tarde.');
                } else {
                    setErrorMessage('Error inesperado. Por favor intente más tarde.');
                }
            } else {
                setErrorMessage('Error de conexión. Por favor intente más tarde.');
            }
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoggedIn) {
        return <ReportComponent token={embedToken} id={id} rol={rol} />;
    }

    return (
        <div className="login-page">
            {isLoading && (
                <div className="loading-container">
                    <img src={loadingGif} alt="Loading..." />
                </div>
            )}
            <div className="login-container" style={{ opacity: isLoading ? 0.5 : 1 }}>
                <a href="/">
                    <img
                        src="/Sagy%20Logo.png"
                        alt="Sagy Logo"
                        className="login-logo"
                    />
                </a>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <h1>Iniciar Sesión</h1>
                        <label htmlFor="id">Usuario (NIT)</label>
                        <input
                            type="text"
                            title="Por favor ingrese un NIT valido."
                            id="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <div className="password-container">
                            <input
                                type="password"
                                title="Por favor ingrese una contraseña válida."
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                aria-required="true"
                            />
                        </div>
                    </div>

                    <button type="submit" className="enviarFormButton" disabled={isLoading}>Ingresar</button>
                </form>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className="forgot-password">
                    <a href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Olvid%C3%A9%20mi%20contrase%C3%B1a%20de%20la%20plataforma.%20%C2%BFPodr%C3%ADas%20ayudarme%2C%20por%20favor%3F" target="_blank" rel="noopener noreferrer">
                        ¿Olvidaste tu contraseña?
                    </a>
                </div>
            </div>
        </div>
    );
}
