import React from 'react'
import './footer.css'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

export default function Footer() {
  return (
    <>
      <div className="footerContainer">
        <div className="footerInner">
          <div className="footerRow">
            <div className="footerColumn columnLogo">
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + '/SagyFooter.png'}
                  alt="SAGY Logo"
                  className="footerLogo"
                />
              </a>
              {/* <p className="footerDescription">
                Ahorro en servicios públicos para las Empresas. Con <strong>Sagy</strong>: ¡si no ahorras, no pagas!
              </p> */}
            </div>
            <div className="footerColumn">
              <h3 className="footerTitle">Contacto</h3>
              <div className="contactItem">
                <a href="https://www.google.com/maps/search/?api=1&query=Calle+67+%23+52-20+Ruta+N,+Torre+A,+Piso+4,+Medellín,+Colombia+050010" className="contactItem">
                  <FaMapMarkerAlt className="contactIcon" />
                  <p>
                    Calle 67 # 52 - 20 Ruta N, Torre A, Piso 4<br />
                    Medellín - Colombia (050010)
                  </p>
                </a>
              </div>
              <div className="contactItem">
                <a href="tel:+573116756456" className="contactItem">
                  <FaPhoneAlt className="contactIcon" />
                  <p>(+57) 311 675 6456</p>
                </a>
              </div>
              <div className="contactItem">
                <a href="mailto:hola@sagy.com.co" className="contactItem">
                  <FaEnvelope className="contactIcon" />
                  <p>hola@sagy.com.co</p>
                </a>
              </div>
            </div>
            <div className="footerColumn">
              <h3 className="footerTitle">Enlaces</h3>
              <ul className="footerLinks">
                <li><a href="/#SectionSoySagy">Soy <strong>Sagy</strong></a></li>
                <li><a href="/reportes">Plataforma: Iniciar sesión.</a></li>
                <li><a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/">¡Ahorro en servicios publicos sin inversión!</a></li>
                <li><a href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Quisiera%20ahorrar%20en%20mi%20factura%20de%20servicios%20p%C3%BAblicos.">¡Hablemos por WhatsApp!</a></li>

              </ul>
            </div>
            <div className="footerColumn">
              <h3 className="footerTitle">Síguenos</h3>
              <div className="socialIcons">
                <a
                  href="https://instagram.com/sagy.co?igshid=YmMyMTA2M2Y="
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + '/InsFooter.png'}
                    alt="Instagram"
                    className="imgFooter"
                  />
                </a>
                <a
                  href="https://www.facebook.com/sagy.col/"
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + '/FbFooter.png'}
                    alt="Facebook"
                    className="imgFooter"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/sagyco/"
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + '/LiFooter.png'}
                    alt="LinkedIn"
                    className="imgFooter"
                  />
                </a>
                <a
                  href="https://wa.me/573116756456?text=%C2%A1Hola%2C%20Sagy!%20Quisiera%20ahorrar%20en%20mi%20factura%20de%20servicios%20p%C3%BAblicos."
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + '/FooterWpp.png'}
                    alt="WhatsApp"
                    className="imgFooter"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottomContainer">
        <div className="footerBottom">
          <a href="/politica-de-privacidad" className="privacyLink">
            Política de tratamiento de datos
          </a>
          <span className="divider">|</span>
          <span className="reservedRights">Derechos Reservados © SAGY 2025</span>
        </div>
      </div>
    </>
  )
}
