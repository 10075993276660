import React from "react";
import "./plataforma.css";

const Plataforma = () => {
    return (
        <section className="sagy-section">
            <div className="header-container">
                <h2 className="nuestraPlataforma">Nuestra plataforma</h2>
                <h1 className="headerPlataforma">Gestión inteligente de servicios públicos</h1>
                {/* <p className="descriptionPlataforma">
                    Ofrecemos una plataforma para la gestión de facturas de servicios públicos, con funcionalidades como:
                </p> */}
            </div>
            <div className="content-container">
                <div className="features-container">
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon1P.png'}
                            alt="Icono 1"
                            className="feature-icon"
                        />
                        <p>Carga automática de facturas.</p>
                    </div>
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon2P.png'}
                            alt="Icono 2"
                            className="feature-icon"
                        />
                        <p>Identificación de oportunidades de ahorro.</p>
                    </div>
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon3P.png'}
                            alt="Icono 3"
                            className="feature-icon"
                        />
                        <p>Tablero de visualización de consumos históricos.</p>
                    </div>
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon4P.png'}
                            alt="Icono 4"
                            className="feature-icon"
                        />
                        <p>Centralización y seguimiento de facturas de múltiples sedes.</p>
                    </div>
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon5P.png'}
                            alt="Icono 5"
                            className="feature-icon"
                        />
                        <p>Comparación de sitios similares (Business Intelligence).</p>
                    </div>
                    <div className="feature-item">

                        <img
                            src={process.env.PUBLIC_URL + '/Icon6P.png'}
                            alt="Icono 6"
                            className="feature-icon"
                        />
                        <p>Indicadores clave para la toma de decisiones.</p>
                    </div>
                </div>
                <div className="image-container">
                    <a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/">
                        <img
                            src={process.env.PUBLIC_URL + '/laptop-sagy-plataforma.png'}
                            alt="Plataforma"
                            className="platform-image"
                        />
                    </a>
                </div>
            </div>
            <div className="button-container">
                <h1 class="plataformaFinal">¡Accede y toma el control</h1>
                <p class="plataformaFinalSecond">de tus facturas!</p>
                <div className="button-container2">
                    {/* <button className="platform-button">
                        <a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/" target="_blank" rel="noopener noreferrer" className="button-link">
                            Probar demo
                        </a>
                    </button> */}
                    <button className="platform-button">
                        <a href="https://outlook.office365.com/book/SagyAhorroenserviciospblicossininversin@sagy.com.co/" target="_blank" rel="noopener noreferrer" className="button-link">
                            Conoce cómo
                        </a>
                    </button>
                </div>
            </div>
        </section>
    );
};
export default Plataforma;
