import React from "react";
import "./medios.css";
// import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";

const MAX_VISIBILITY = 3;

const Card = ({ title, content, imageSrc, buttonText, buttonLink, date, source }) => (
    <div className="card">
        <h2>{title}</h2>
        <hr className="mention-line" />
        {date && <p className="card-date">{date}</p>}
        {/* {imageSrc && <img src={imageSrc} alt={`${title} image`} className="card-image" />} */}
        <img src={imageSrc} alt={title} className="card-image" />
        <p>{content}</p>
        {source && <p className="card-source"><strong>Fuente:</strong> {source}</p>}
        {buttonText && buttonLink && (
        <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <button className="card-button">{buttonText}</button>
        </a>
        )}
    </div>
);

const Carousel = ({ children }) => {
    const [active, setActive] = React.useState(3);
    const count = React.Children.count(children);

    return (
        <div className="carousel">
            {active > 0 && (
                <button className="nav left" onClick={() => setActive((i) => i - 1)}>
                   {"<"}  {/* <TiArrowLeftThick /> */}
                </button>
            )}
            {React.Children.map(children, (child, i) => (
                <div
                    className="card-container"
                    style={{
                        "--active": i === active ? 1 : 0,
                        "--offset": (active - i) / 3,
                        "--direction": Math.sign(active - i),
                        "--abs-offset": Math.abs(active - i) / 3,
                        pointerEvents: active === i ? "auto" : "none",
                        opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
                        display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
                    }}
                >
                    {child}
                </div>
            ))}
            {active < count - 1 && (
                <button className="nav right" onClick={() => setActive((i) => i + 1)}>
                    {">"} {/* <TiArrowRightThick /> */}
                </button>
            )}
        </div>
    );
};

const CARDS_DATA = [
    {
        title: "Cómo ahorrar dinero en tarifas de servicios públicos",
        content: "La nota aborda estrategias prácticas para reducir costos en los servicios públicos y optimizar el consumo en hogares y empresas, destacando la importancia de la eficiencia energética y el control del consumo de agua y electricidad.",
        imageSrc: process.env.PUBLIC_URL + 'rcn.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.noticiasrcn.com/economia/como-ahorrar-dinero-en-tarifas-de-servicios-publicos-446247",
        date: "18 de mayo de 2023",
        source: "Noticias RCN"
    },
    {
        title: "Cómo las empresas pueden ahorrar en servicios públicos y generar ganancias",
        content: "El artículo presenta formas en que las empresas pueden reducir costos en servicios públicos mediante la implementación de tecnología, estrategias de eficiencia energética y optimización de recursos.",
        imageSrc: process.env.PUBLIC_URL + 'semana.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.semana.com/economia/empresas/articulo/como-las-empresas-pueden-ahorrar-en-servicios-publicos-y-generar-ganancias/202347/",
        date: "24 de mayo de 2023",
        source: "Semana"
    },
    {
        title: "Cómo las empresas pueden ahorrar en servicios públicos y aumentar sus ganancias",
        content: "Explora diversas tácticas para que las empresas logren una mayor eficiencia en sus servicios públicos, incluyendo la reducción del desperdicio de energía y la adopción de fuentes renovables.",
        imageSrc: process.env.PUBLIC_URL + 'lafm.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.lafm.com.co/economia/como-las-empresas-pueden-ahorrar-en-servicios-publicos-y-aumentar-sus-ganancias",
        date: "19 de mayo de 2023",
        source: "La FM"
    },
    {
        title: "Él utiliza analítica de datos para optimizar los costos de los servicios públicos",
        content: "El artículo destaca cómo la analítica de datos es clave para reducir costos en servicios públicos al identificar y eliminar ineficiencias en el consumo energético y de recursos.",
        imageSrc: process.env.PUBLIC_URL + 'elespectador.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.elespectador.com/economia/emprendimiento-y-liderazgo/el-utiliza-analitica-de-datos-para-optimizar-los-costos-de-los-servicios-publicos/",
        date: "26 de julio de 2023",
        source: "El Espectador"
    },
    {
        title: "Sagy: una startup que ayuda a las empresas a ahorrar hasta 30% en el costo de los servicios públicos",
        content: "Sagy, una startup colombiana, utiliza tecnología avanzada para optimizar los costos en servicios públicos, permitiendo a las empresas ahorrar significativamente y mejorar su competitividad.",
        imageSrc: process.env.PUBLIC_URL + 'larepublica.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.larepublica.co/empresas/sagy-una-startup-que-ayuda-a-las-empresas-a-ahorrar-hasta-30-en-el-costo-de-los-servicios-publicos-3658715",
        date: "14 de julio de 2023",
        source: "La República"
    },
    {
        title: "Empresas en Colombia: cómo ahorrar en servicios públicos",
        content: "Este artículo explica estrategias clave que las empresas colombianas pueden implementar para reducir el costo de los servicios públicos, destacando la eficiencia energética y el uso racional de recursos.",
        imageSrc: process.env.PUBLIC_URL + 'yahoo-finanzas.png',
        buttonText: "Leer artículo",
        buttonLink: "https://es-us.finanzas.yahoo.com/noticias/empresas-colombia-ahorrar-servicios-p%C3%BAblicos-210000082.html?guccounter=1",
        date: "03 de julio de 2023",
        source: "Yahoo Finanzas"
    },
    {
        title: "Así pueden las empresas ahorrar en servicios públicos y aumentar ganancias",
        content: "El artículo analiza cómo las empresas pueden ahorrar dinero y aumentar sus ganancias mediante prácticas de eficiencia energética y la implementación de tecnología moderna para el manejo de servicios públicos.",
        imageSrc: process.env.PUBLIC_URL + 'valora-analitik.png',
        buttonText: "Leer artículo",
        buttonLink: "https://www.valoraanalitik.com/2023/07/03/asi-pueden-las-empresas-ahorrar-en-servicios-publicos-y-aumentar-ganancias/",
        date: "3 de julio de 2023",
        source: "Valora Analitik"
    }
];

export default function Medios() {
    return (
        <div className="carousel-section" id="carousel-section">
            <h1 className="carousel-header">Sagy</h1>
            <p className="carousel-description">en los medios</p>
            <Carousel>
                {CARDS_DATA.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        // date={card.date}
                        content={card.content}
                        imageSrc={card.imageSrc}
                        buttonText={card.buttonText}
                        buttonLink={card.buttonLink}
                        source={card.source}
                    />
                ))}
            </Carousel>
        </div>
    );
}
